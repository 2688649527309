const tabOffer = () => {

    window.addEventListener('keydown', (e) => {
        if (e.keyCode == 9) {
            e.preventDefault();
            return;
        }
    });

}

export {tabOffer}