import SmoothScroll from 'smoothscroll-for-websites';

const smoothScroll = () => {

    SmoothScroll({
        // Scroll time 800 = 0.8 seconds
        animationTime: 800,
        // Step size in pixels
        stepSize: 75,

        // Additional settings:
        
        // Acceleration 
        accelerationDelta: 30,
        // Maximum acceleration
        accelerationMax: 2,

        // Keyboard support
        keyboardSupport: true,
        // Step scroll arrows on the keyboard in pixels
        arrowScroll: 50,

        // Pulse (less tweakable) ratio of "tail" to "acceleration"
        pulseAlgorithm: true,
        pulseScale: 4,
        pulseNormalize: 1,

        // Touchpad support
        touchpadSupport: true,
    })

}

export {smoothScroll};